import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment.prod';

import { Observable } from 'rxjs';
import { PagedInterface } from '../models/paged.interface';
import { TeacherInterface, TeacherSelectInterface } from '../models/teacher.interface';
import { TeacherRegistrationInterface } from '../models/teacher-registration.interface';

@Injectable()
export class TeacherService {

  private API_URL = environment.apiUrl;
  private globalOptions = environment.httpOptions;

  constructor(private httpClient: HttpClient) {
  }

  createTeacher(teacher: TeacherRegistrationInterface, image: File): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };

    const studentFormData = new FormData();
    studentFormData.append('form', new Blob([JSON.stringify(teacher)], { type: 'application/json' }));
    studentFormData.append('file', image);
    return this.httpClient.post(`${this.API_URL}/professors`, studentFormData, httpOptions);
  }

  getTeachers(pageNumber: number = 0, pageSize: number = 10): Observable<PagedInterface<TeacherInterface>> {
    return this.httpClient.get<PagedInterface<TeacherInterface>>(`${this.API_URL}/professors`, {
      params: new HttpParams({
        fromObject: {
          'pageNumber': '' + pageNumber,
          'pageSize': '' + pageSize
        }
      })
    });
  }

  getAllTeachers(): Observable<TeacherSelectInterface[]> {
    return this.httpClient.get<TeacherSelectInterface[]>(`${this.API_URL}/professors/list`);
  }

  getTeacherById(professorId: any): Observable<TeacherSelectInterface> {
    return this.httpClient.get<TeacherSelectInterface>(`${this.API_URL}/professors/${professorId}`);
  }

  deleteTeacher(teacherId: string): Observable<any> {
    return this.httpClient.delete(`${this.API_URL}/professors/${teacherId}`);
  }

  getSpecialities(): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/specialties`);
  }

  getClass(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.API_URL}/professional-classes`);
  }

  updateTeacher(professorId: any, teacher: TeacherRegistrationInterface, image: File): Observable<any> {
    return this.httpClient.put(`${this.API_URL}/professors/${professorId}`, teacher);
  }
}
