import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: true,
  apiUrl: 'https://api.danxax.com',
  httpOptions: {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':  '*'
    })
  }
};
